import React, { useEffect, useState, useMemo } from 'react';
import { Toast } from 'antd-mobile';
import invariant from 'invariant';
import { img_bg } from '@/assets/recharge';
import { Input, Button, Card, City } from '@/component';
import { useLocation } from 'react-router-dom';
import { qs, checkResponseSuccess } from '@/common/request';
import { getAddChargeInfo, IAddChargeInfo, addQr, IAddQrParams } from './api';
import './index.less';
import WarmHint from '@/component/warmHint';
import { phoneRegExg } from '@/utils/validate';

const Purchase: React.FC<any> = () => {
  const location = useLocation();
  const { id } = qs.parse(location.search);
  const cssPreFix = 'ct-purchase';

  const [data, setData] = useState({} as IAddChargeInfo);
  const [formvalue, setFormValue] = useState<Omit<IAddQrParams, 'id'> & { city: string[] }>({
    customerName: '',
    customerPhone: '',
    managerName: '',
    managerPhone: '',
    city: [],
    address: '',
  });

  const cardInfo = useMemo(() => {
    return [
      {
        title: '商品名称',
        dataIndex: 'sellName',
      },
      {
        title: '销售价格',
        dataIndex: 'sellPrice',
        render: (value: string) => `${value ?? ''}元`,
      },
      {
        title: '有效周期',
        dataIndex: 'sellName',
        render: (value: string) => `${value ?? ''}个月`,
      },
    ];
  }, []);

  const customInfo = useMemo(
    () => [
      { name: 'customerName', placeholder: '请输入您的姓名' },
      { name: 'customerPhone', placeholder: '请输入您的手机号码', type: 'tel' },
    ],
    []
  );
  const managerInfo = useMemo(
    () => [
      { name: 'managerName', placeholder: '请输入您的客户经理姓名' },
      { name: 'managerPhone', placeholder: '请输入您的客户经理手机号码', type: 'tel' },
    ],
    []
  );

  const handleInputChange = (name: string, value: string | string[]) => {
    setFormValue((prev) => {
      const newFormValue = { ...prev, [name]: value };
      if (name === 'city') {
        newFormValue.address = '';
      }
      return newFormValue;
    });
  };

  const onSubmit = async () => {
    try {
      const currentFormValue = { ...formvalue };
      invariant(!!id, '没有二维码id');
      [...customInfo, ...managerInfo].forEach((item) => {
        const value = (currentFormValue as any)?.[item.name];
        invariant(!!value, item.placeholder);
        if (item.name.includes('Phone')) {
          invariant(!!phoneRegExg.test(value), item.placeholder.replace('输入', '正确输入'));
        }
      });
      invariant(!!currentFormValue.city?.length, '请选择省市区');
      invariant(!!currentFormValue.address, '请输入详细地址');
      Toast.loading('loading...', 0);
      currentFormValue.address = currentFormValue.city.join('').concat(currentFormValue.address);
      delete (currentFormValue as any).city;
      const response = await addQr({ id, ...currentFormValue });
      invariant(checkResponseSuccess({ response, type: 2 }), response?.msg);
      const payUrl = response.data;
      window.location.href = payUrl;
      Toast.hide();
    } catch (error: any) {
      const message = error?.message?.replace('<br/>', '，');
      Toast.info(message || '请求失败,请重试');
    }
  };

  useEffect(() => {
    document.title = '欢迎使用升腾云喇叭';
    getData();
  }, []);

  const getData = async () => {
    try {
      Toast.loading('loading...', 0);
      const response = await getAddChargeInfo({ id });
      invariant(checkResponseSuccess({ response, type: 2 }), response?.msg);
      setData(response.data);
      Toast.hide();
    } catch (error: any) {
      Toast.info(error.message || '请求失败,请重试');
    }
  };

  const renderInput = (inputs: { name: string; placeholder: string; type?: string }[]) => {
    return inputs.map((item) => {
      const { name, placeholder, type = 'text' } = item;
      return (
        <Input
          key={name}
          value={(formvalue as any)?.[name]}
          onChange={handleInputChange.bind(null, name)}
          placeholder={placeholder}
          type={type}
        />
      );
    });
  };

  return (
    <div className={`${cssPreFix}`}>
      <img className={`${cssPreFix}-banner`} src={img_bg} />
      <Card list={cardInfo} data={data} style={{ margin: '16vw auto 3vw' }} />
      <div className={`${cssPreFix}-form`}>
        <div className={`${cssPreFix}-form-title`}>客户信息</div>
        {renderInput(customInfo)}

        <div className={`${cssPreFix}-form-title`}>客户经理信息</div>
        {renderInput(managerInfo)}

        <div className={`${cssPreFix}-form-title`}>分行地址</div>

        <City value={formvalue.city} onChange={handleInputChange.bind(null, 'city')} />
        <Input
          value={formvalue.address}
          onChange={handleInputChange.bind(null, 'address')}
          placeholder='请输入详细地址'
        />

        <Button onClick={onSubmit}>立即购买</Button>
      </div>

      <WarmHint content={data?.content} />
    </div>
  );
};

export default Purchase;
