import ApiRequest, { IResponseResult2, qs } from '@/common/request';

export interface IRechargeInfo {
  sellName: string;
  sellPrice: string;
  payPeriod: string;
  content: string;
  extraFields: string;
}
export interface IOnRechargeParams {
  qrId: string;
  linkmanPhone: string;
  cardno: string;
}

/**
 * 获取卡信息
 */
export const getRechargeInfo = async (params: { qrId: string }): Promise<IResponseResult2<IRechargeInfo>> => {
  const { qrId } = params;
  return ApiRequest.get(`/iotc-wxmp/qrchargeInfo?id=${qrId}`);
};

/**
 * 充值
 */
export const onRecharge = async (params: IOnRechargeParams): Promise<IResponseResult2<string>> => {
  return ApiRequest.post(`/iotc-wxmp/qrcharge1?${qs.stringfy(params)}`, {});
};
