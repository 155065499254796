import React, { CSSProperties, ReactNode } from 'react';
import './index.less';

interface CardProps {
  className?: string;
  style?: CSSProperties;
  list: {
    title: JSX.Element | string;
    dataIndex: string;
    className?: string;
    render?: (value: any, record: object) => ReactNode;
  }[];
  data: {
    [key: string]: any;
  };
}

const Card: React.FC<CardProps> = (props) => {
  const { className, style, list, data, ...restProps } = props;
  const cssPreFix = 'ct-card';

  return (
    <div className={`${cssPreFix} ${className}`} style={style} {...restProps}>
      {list?.map((item, index) => {
        let value = data[item.dataIndex];
        if (item.render) {
          value = item.render(value, data);
        }

        return (
          <div className={`${cssPreFix}-row ${item.className ?? ''}`} key={index}>
            <div>{item.title}</div>
            <div>{value}</div>
          </div>
        );
      })}
    </div>
  );
};

Card.defaultProps = {
  className: '',
};

export default Card;
