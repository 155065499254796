import React from 'react';
import './index.less';

interface WarnHintProps {
  content: string | undefined;
}

const cssPreFix = 'ct-warm-hint';

const WarmHint: React.FC<WarnHintProps> = (props) => {
  const { content } = props;

  if (!content) return null;

  const list = content.split(/[;|；]/);

  return (
    <div className={`${cssPreFix}`}>
      <div className={`${cssPreFix}-header`}>温馨提示：</div>
      {list?.map((str, index) => {
        return (
          <div className={`${cssPreFix}-tip`} key={index}>
            {index + 1}. {str}
            {index === list.length - 1 ? '' : '；'}
          </div>
        );
      })}
    </div>
  );
};

export default WarmHint;
