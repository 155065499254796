import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import Index from '@/page/index';
import Recharge from '@/page/recharge';
import Purchase from '@/page/purchase';

const RouterContainer = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path='/recharge' exact component={Recharge} />
        <Route path='/purchase' exact component={Purchase} />
        <Route component={Index} />
      </Switch>
    </HashRouter>
  );
};

export default RouterContainer;
