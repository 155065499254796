import ApiRequest, { IResponseResult2, formatJsonToFormData } from '@/common/request';

export interface IAddChargeInfo {
  sellName: string;
  sellPrice: string;
  payPeriod: string;
  content: string;
}

export interface IAddQrParams {
  id: string;
  customerName: string;
  customerPhone: string;
  managerName: string;
  managerPhone: string;
  address: string;
}

export const getAddChargeInfo = async (params: { id: string }): Promise<IResponseResult2<IAddChargeInfo>> => {
  const result = ApiRequest.get(`/iotc-wxmp/qraddChargeInfo?`, params);
  return result;
};

export const addQr = async (params: IAddQrParams): Promise<IResponseResult2<any>> => {
  const result = await ApiRequest.post('/iotc-wxmp/qradd', params);
  return result;
};
