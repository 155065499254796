import React, { useState, CSSProperties } from 'react';
import { Picker, Icon } from 'antd-mobile';
import citydata, { CityItem } from './data';
import './index.less';

const formatCity = (obj: CityItem) => {
  let result: Date[] = [];
  for (let key in obj) {
    const data = obj[key];
    if (typeof data === 'string') {
      result.push({
        value: data,
        label: data,
      });
    } else {
      const { name, child } = data;
      const children = formatCity(child);
      result.push({
        value: name,
        label: name,
        children: children,
      });
    }
  }
  return result;
};

const data = formatCity(citydata);

interface Date {
  value: string;
  label: string;
  children?: Date[];
}

interface CityProps {
  className?: string;
  style?: CSSProperties;
  value?: string[];
  onChange?: (value: string[]) => void;
}

const prefixCls = 'ct-city';

const City: React.FC<CityProps> = (props) => {
  const { className, style, value, onChange } = props;
  const [cityValue, setCityValue] = useState(value);

  const handleOk = (value: string[]) => {
    setCityValue(value);
    return onChange?.(value);
  };

  return (
    <Picker data={data} value={cityValue} cols={3} onOk={handleOk}>
      <div className={`${prefixCls} ${className} ${cityValue?.length ? '' : ' placeholder'}`} style={style}>
        {cityValue?.join(',') || '请选择省市区'}
        <Icon type='down' />
      </div>
    </Picker>
  );
};

City.defaultProps = {
  className: '',
};

export default City;
