import ApiRequest, { IResponseResult } from '@/common/request';

export interface ICardInfo {
  snCode: string;
  cardNo: string;
  status: number;
  contractDate: string;
  iccid: string;
  qrId: string | number;
  isIntercept: '0' | '1'; // 0充值点击事件拦截
  msg: string; // 拦截信息
}

/**
 * @todo 根据设备号信息，查询卡信息
 */
export const getCardInfo = async (params?: { snCode: string }): Promise<IResponseResult<ICardInfo>> => {
  return ApiRequest.post(`/iotc-wxmp/v2/query/cardInfo`, params);
};
