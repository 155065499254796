import React, { CSSProperties } from 'react';
import './index.less';

interface ButtonProps {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { className, style, onClick, children } = props;
  const cssPreFix = 'ct-button';

  return (
    <button className={`${cssPreFix} ${className}`} style={style} onClick={onClick}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  className: '',
};

export default Button;
