import { BASE_URL } from './config';

/**
 * 接口返回数据的范型
 */
export interface IResponseResult<T> {
  code: number;
  data: T;
  msg: string;
  serverTime?: string;
}

export interface IResponseListResult<T> {
  code: string;
  data: {
    rows: Array<T>;
    total: number;
  };
  msg: string;
}

export interface IResponseResult2<T> {
  success: boolean;
  msg: string;
  data: T;
}

type checkResponseSuccessProps<T = any> =
  | {
      response: IResponseListResult<T> | IResponseResult<T>;
      type: 1;
    }
  | {
      response: IResponseResult2<T>;
      type: 2;
    };

/**
 * 检测接口结果是否成功
 */
export const checkResponseSuccess = <T>({ response, type }: checkResponseSuccessProps<T>) => {
  if (type === 1) {
    return (response as IResponseResult<T>).code === 0;
  }
  return (response as IResponseResult2<T>).success;
};

/**
 * queryString 与 object 互转
 */
export class qs {
  static stringfy(obj: object) {
    const params = new URLSearchParams(obj as Record<string, string>);
    return params.toString();
  }

  static parse(str: string) {
    const params = new URLSearchParams(str);
    const newParams: any = {};
    Array.from(params.entries()).forEach(([key, value]) => {
      newParams[key] = value;
    });
    return newParams;
  }
}

/**
 * 将body数据转换为urlencode类型
 */
export const formatJsonToFormData = (data: object, formData?: FormData): FormData => {
  const newFormData = formData ? formData : new FormData();
  Object.entries(data).forEach(([key, value]) => {
    newFormData.append(key, value);
  });
  return newFormData;
};

class ApiRequest {
  baseOptions(params: any, method: string = 'GET'): Promise<any> {
    let { url, data } = params;
    // let contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    let contentType = 'application/json;charset=UTF-8';
    contentType = params.contentType || contentType;
    const option: any = {
      headers: {
        'Content-Type': contentType,
      },
      method: method,
      body: data,
      credentials: 'include',
    };
    return fetch(BASE_URL + url, option)
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  get(url: string, data: object = {}) {
    let option = { url: `${url}${qs.stringfy(data)}` };
    return this.baseOptions(option);
  }

  post(url: string, data: any, contentType?: string) {
    let params = {
      url,
      data: JSON.stringify(data),
      contentType,
    };
    return this.baseOptions(params, 'POST');
  }

  put(url: string, data: string = '') {
    let option = { url, data };
    return this.baseOptions(option, 'PUT');
  }

  delete(url: string, data: string = '') {
    let option = { url, data };
    return this.baseOptions(option, 'DELETE');
  }
}

export default new ApiRequest();
