import React, { CSSProperties, useCallback, useState, useRef } from 'react';
import './index.less';

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  className?: string;
  style?: CSSProperties;
  value: string;
  onChange: (value: string) => void;
}

const Input: React.FC<InputProps> = (props) => {
  const { className, style, value, onChange, ...restProps } = props;
  const cssPreFix = 'ct-input';

  const handleChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  }, []);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      (e.target as any)?.scrollIntoViewIfNeeded?.();
    }, 500);
  };

  return (
    <input
      role='input'
      className={`${cssPreFix} ${className}`}
      style={style}
      value={value}
      onFocus={handleFocus}
      onChange={handleChange}
      {...restProps}
    />
  );
};

Input.defaultProps = {
  className: '',
};

export default Input;
