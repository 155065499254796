export const isDevelopment = () => process.env.NODE_ENV === 'development';
const pre = window.location.origin;
export const BASE_URL = isDevelopment() ? '' : pre;

export const RESPONSE_CODE = {
  success: 0,
};

export const BASIC_CONFIG = {
  BASE_URL,
};
