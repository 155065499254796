import { useRef, useCallback } from 'react';

const noop = () => {};

function usePersistFn(fn: any) {
  const ref = useRef<any>(() => {
    throw new Error('cannot call an event handle while rendering');
  });

  ref.current = fn;

  const persist = useCallback((...args) => {
    const refFn = ref.current;
    if (refFn) {
      return refFn(...args);
    }
  }, []);

  if (typeof fn === 'function') {
    return persist;
  }

  return noop;
}

export default usePersistFn;
