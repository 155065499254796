import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Toast,Carousel,Modal } from 'antd-mobile';
import { useLocation, useHistory } from 'react-router-dom';
import invariant from 'invariant';
import {invariant as invariantfn} from '@/utils/validate';
import { qs } from '@/common/request';
import { Card, Button, Input } from '@/component';
import { getRechargeInfo, IRechargeInfo, onRecharge } from './api';
import { img_home_banner, icon_scan ,icon_qrcode_scan} from '@/assets/index';
import { icon_home_shangpin } from '@/assets/recharge';
import './index.less';
import WarmHint from '@/component/warmHint';
import { phoneRegExg } from '@/utils/validate';
import usePersistFn from '@/utils/usePersistFn';

const cssPreFix = 'ct-recharge-container';

export interface IIotCardInfo {
  title: string;
  value: string | undefined;
  icon?: string;
  className?: string;
}

const importantFieldsLabel: { [key: string]: string } = {
  phone: '联系人手机号码',
  sn: 'sn',
  cardNo: '充值卡号',
  undefined: '',
};

const Index: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { qrId, cardNo, msg, importantFields } = qs.parse(location.search);
  const [data, setData] = useState({} as IRechargeInfo);
  const [visible, setvisible] = useState(false)

  const [formValue, _setFormValue] = useState<any>(() => {
    const p: any = { qrId };
    if (cardNo) {
      p.cardNo = cardNo;
    }
    return p;
  });
  const [remark, _setRemark] = useState<any>({});

  const setFormValue = useCallback((value, key) => {
    _setFormValue((prev: any) => {
      return { ...prev, [key]: value };
    });
  }, []);

  const setRemark = useCallback((value, key) => {
    _setRemark((prev: any) => {
      return { ...prev, [key]: value };
    });
  }, []);

  useEffect(() => {
    document.title = '流量卡充值';
    if (msg) {
      Toast.info(decodeURI(msg));
      setTimeout(() => {
        // history.replace(`/recharge?cardNo=${cardNo}&qrId=${qrId}`);
        getData();
      }, 2000);
    } else {
      getData();
    }
  }, []);

  const getData = usePersistFn(async () => {
    try {
      Toast.loading('loading...', 0);
      const result = await getRechargeInfo({ qrId });
      invariant(result.success, result?.msg || '请求失败');
      setData(result.data);
      Toast.hide();
    } catch (error) {
      Toast.info(error?.message || '请求失败,请重试');
    }
  });

  const onSubmit = usePersistFn(async () => {
    try {
      if (importantFields.includes('cardNo')) {
        invariant(!!formValue.cardNo, '请输入充值卡号');
      }
      if (importantFields.includes('sn')) {
        invariant(!!formValue.sn, '请输入sn号');
      }
      if (importantFields.includes('phone')) {
        invariant(!!formValue.phone, '请输入联系人手机号码');
        invariant(!!phoneRegExg.test(formValue.phone), '请正确输入联系人手机号码');
      }
      
  
      Toast.loading('loading...', 0);
//       请求参数加一个：paySource
// 参数类型：Integer
// 参数定义：1：支付宝 2：微信
      const paySource = navigator.userAgent.indexOf('AlipayClient')!=-1  ? 1: 2
      const result = await onRecharge({
        ...formValue,
        paySource: paySource,
        remark: Object.entries(remark)
          .map((item) => item.join(':'))
          .join(','),
      });
      // alert(JSON.stringify(result))
      invariant(result.success, result?.data === '00' ? `${navigator.userAgent.indexOf('AlipayClient')!=-1? '支付宝': 
      '微信'}渠道被关闭` : result?.msg || '请求失败')
      // if(){
        const payUrl = result.data;
        window.location.href = payUrl;
        Toast.hide();
      // }
  
    } catch (error) {
      const message = error?.message?.replace('<br/>', '，');
      Toast.info(message || '请求失败,请重试');
    }
  });

  // ==== 套餐信息 =====
  const cardInfo = useMemo(() => {
    return [
      {
        title: <img className={`icon`} src={icon_home_shangpin} />,
        dataIndex: 'sellName',
        className: 'product-name',
      },
      {
        title: '套餐价格',
        dataIndex: 'sellPrice',
        render: (value: string) => `${value ?? ''}元`,
      },
      {
        title: '续费周期',
        dataIndex: 'payPeriod',
        render: (value: string) => `${value ?? ''}个月`,
      },
    ];
  }, []);
  const onBtnClick = () => {
    if(navigator.userAgent.indexOf('AlipayClient')!=-1 || navigator.userAgent.indexOf('MicroMessenger')!=-1){
        console.log(!!formValue.phone, phoneRegExg.test(formValue.phone));
      if (importantFields.includes('phone') && invariantfn(!!formValue.phone, `${'请输入联系人手机号码'}`) && 
      invariantfn(phoneRegExg.test(formValue.phone), "请正确输入联系人手机号码")) {          
        
      
        Modal.alert('提示',<div>请确认手机号：<span 
          // style={{fontWeight:'bold', color: '#000'}}
          >
            {formValue.phone}</span></div>,
            [
              {text: '取消', onPress: () => {}},
              {text: '确定', onPress: onSubmit},
            ]
            )
      } 
  
    } else {
      Toast.info("请在支付宝或微信中打开");
    }
  }
  return (
    <div className={cssPreFix}>
      {/* <img className={`${cssPreFix}-banner`} src={img_home_banner} /> */}
      <Carousel
        autoplay={true}
        infinite
      >
        {/* <img className={`${cssPreFix}-banner`} src={icon_scan} onClick={() => {setvisible(true)}} /> */}
        <img className={`${cssPreFix}-banner`} src={img_home_banner} />

      </Carousel>  
      <Card list={cardInfo} data={data} className={`${cssPreFix}-card`} />
      <div className={`${cssPreFix}-form`}>
        {importantFields?.split(',')?.map?.((item: string) => {
          if (!item) return null;
          return (
            <Input
              key={item}
              value={formValue[item]}
              onChange={(value) => setFormValue(value, item)}
              disabled={item == 'cardNo' && cardNo}
              placeholder={`请输入${importantFieldsLabel[item]}`}
            />
          );
        })}
        {data?.extraFields?.split(',')?.map?.((item: string) => {
          if (!item) return null;
          return (
            <Input
              key={item}
              value={remark[item]}
              onChange={(value) => setRemark(value, item)}
              placeholder={`请输入${item}`}
            />
          );
        })}
        <Button onClick={onBtnClick}>立即续费</Button>
      </div>
      <WarmHint content={data?.content}></WarmHint>
      <Modal
          visible={visible}
          transparent
          maskClosable={false}
          onClose={() => {setvisible(false)}}
          title="长按识别二维码"
          footer={[{ text: '关闭', onPress: () => { setvisible(false); } }]}
        >
            <img className={`${cssPreFix}-scan`} src={icon_qrcode_scan} />
        </Modal>
    </div>
  );
};

export default Index;
